<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    :show-close="true"
    :center="true"
    :before-close="handleClose"
    title="审批详情"
    width="800px"
    
  >
    <div>
      <el-form ref="form" :model="formData"   label-width="100px">

        <el-form-item label="职位" prop="title" >  
          {{ formData.title }}
        </el-form-item>
        <el-form-item label="昵称	" prop="nickname" >  
          {{ formData.nickname }}
        </el-form-item>

        <!--
        <el-form-item label="头像	" prop="avatar" >  
          <el-image
            style="width: 100px; height: 100px"
            :src="formData.user_avatar"
            :fit="fit"></el-image>
        </el-form-item>
-->
        <el-form-item label="提交时间	" prop="created_at" >  
          {{ formData.created_at }}
        </el-form-item>
        <el-form-item label="审批类型	" prop="link" >  
          <!--1,请假申请 ，2出差，加班申请，3报销审批-->
          <span v-if="formData.link==1">请假申请</span>
          <span v-if="formData.link==2">出差，加班申请</span>
          <span v-if="formData.link==3">报销审批</span>
        </el-form-item>

        <el-form-item label="类型	" prop="type" >  
          <!--/1病假2婚假3事假4丧假5出差6加班7办公费8差旅费-->
          <span v-if="formData.type==1">病假</span>
          <span v-if="formData.type==2">婚假</span>
          <span v-if="formData.type==3">事假</span>
          <span v-if="formData.type==4">丧假</span>
          <span v-if="formData.type==5">出差</span>
          <span v-if="formData.type==6">加班</span>
          <span v-if="formData.type==7">办公费</span>
          <span v-if="formData.type==8">差旅费</span>
        </el-form-item>


    
        <el-form-item label="起止时间	" prop="link" v-if="formData.link==1||formData.link==2">  
          {{ formData.min_time }}- {{ formData.max_time }}
        </el-form-item>

        <el-form-item label="报销时间	" prop="link" v-if="formData.link==3">  
          {{ formData.refund_time }}
        </el-form-item>


        <el-form-item label="报销金额	" prop="money" v-if="formData.link==3">  
          {{ formData.money }}
        </el-form-item>

        <el-form-item label="事由	" prop="mark" >  
          {{ formData.mark }}
        </el-form-item>

        
        <el-row  v-for="(item, index) in formData.group_content2" :key="index">
            <el-form-item  :label="item.title"  >

               {{ item.value }}
            </el-form-item>
        
        </el-row>

        <!--        1待审核2审核通过3审核拒绝-->
        <el-form-item label="审批" v-if="formData.status==1" required="">
          <el-radio-group v-model="auditFormData.status" size="small">
            <el-radio label="2" border>同意</el-radio>
            <el-radio label="3" border>拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        	
        <el-form-item label="驳回原因" v-if="formData.status==1" required="">
          <el-input type="textarea" v-model="auditFormData.content"></el-input>
        </el-form-item>
        
    
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmit" v-if="formData.status==1">确定</el-button>
      <el-button @click="visible = false">取 消</el-button>
    </span>


  </el-dialog>
</template>

<script>
import { jobList,projectList,leaveList,leaveInfo,leaveUpdate} from "@/api/application/index";

export default {

  data() {
    return {
      visible: false,
      leave_id:"",
      formData: {
        leave_id:"",
        alias_name:"",//用户名
        user_avatar:"",
        created_at: "",//提交时间
        title: "",//职位名

        type: "",//1病假2婚假3事假4丧假5出差6加班7办公费8差旅费
        link:"",//1,请假申请，2出差，加班申请，3报销审批

        money:"",//报销金额
        refund_time:"",//报销时间

        hasDetaileds:[],//报销列表

        min_time:"",//请假开始时间
        max_time:"",//请假结束时间

        mark:"",//事由
        group_content:[],
        group_content2:[],
        status:"",
        hasDirector:[],
        hasCopy:[]
      
      },

      auditFormData:{
        status:null,
        link:null,
        leave_id:null,
        content:""
      }
       
    };
  },

 
  mounted() {
    console.log("asdasdasd")

},
  
  methods: {
    handleClose() {
      this.visible = false;
      this.$refs["form"].resetFields();

    },


    init(leave_id) {
      this.formData.leave_id=leave_id;
      this.leave_id=leave_id;
      this.initData();
      this.visible = true;
    },

    initData(){
      var that=this;
      leaveInfo({"leave_id":that.formData.leave_id}).then((response) => {
        console.log(response.data.group_content);
   

        if(response.code==200){
          that.formData=response.data

          let groups=[];
          var group_content=response.data.group_content;
          let keys = Object.keys(group_content)
          for (let i =0; i < keys.length; i++) {
            
              groups.push({
                title:keys[i],
                value:group_content[keys[i]],
              })
          }
          that.formData.group_content2=groups;

        }else{
          this.$message({
            message: response.msg?response.msg:"获取信息失败",
          });
        }
      });
    },

   
    
    
    onSubmit(){
      var that=this;
      that.auditFormData.link=that.formData.link;
      that.auditFormData.leave_id=that.leave_id
      leaveUpdate(that.auditFormData).then((response) => {
        console.log(response);
        if(response.code==200){
          this.$message({
              message: "提交成功",
              type: 'success'

          });
          that.$emit('refresh');
          that.visible = false;
        
        }else{
          this.$message({
            message: response.msg?response.msg:"提交失败",
          });
        }
      });

    },

    
  },

 
};
</script>

<style lang="scss" scoped>



/deep/ .myWindow {
    border-radius: 15px;



.el-button--primary {
  color: #FFF;
  background-color: #717ebb;
  border-color: #717ebb;
}


/*
.footer{
  display: flex;
  justify-content: center;
}*/


  }
</style>
