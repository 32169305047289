<template>
  <div class='container'>

    <el-row style="margin-bottom:40px">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/application' }">应用中心</el-breadcrumb-item>
        <el-breadcrumb-item >审批列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
   
    <div class="report_main">
      <el-form :inline="true" :model="searchFormData" class="demo-form-inline">
        <el-form-item label="项目">
          <el-select v-model="searchFormData.project_id" placeholder="请选择" clearable>
            <el-option
              v-for="item in projectDataList"
              :key="item.project_id"
              :label="item.name"
              :value="item.project_id">
            </el-option>
        </el-select>

        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="searchFormData.link" placeholder="请选择" clearable>
            <el-option label="请假" value="1" key="1"></el-option>
            <el-option label="出差加班" value="2" key="2"></el-option>
            <el-option label="报销" value="3" key="3"></el-option>
          </el-select>
        </el-form-item>



        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>


        <el-table
          ref="table"
          v-loading="searchLoading"
          :data="pageProps.records"
          element-loading-text="正在查询"
          fit
          stripe
          highlight-current-row
        >

          <el-table-column align="center" label="项目名称" prop="project_name"> </el-table-column>
          <el-table-column align="center" label="职位"  prop="title"/>
          <el-table-column align="center" label="昵称"  prop="nickname"/>
          <el-table-column align="center" label="姓名"  prop="name"/>
          <el-table-column align="center"  label="手机号码" prop="mobile" />

          <el-table-column align="center" prop="sex" label="性别" >
            <template slot-scope="{row}">
                <span v-if="row.sex==1">男</span>
                <span v-if="row.sex==2">女</span>
                <span v-if="row.sex==0">不公开</span>
              </template>
        </el-table-column>


    

          <el-table-column align="center" prop="link" label="审批类型" >
            <template slot-scope="{row}">
                <span v-if="row.link==1">请假</span>
                <span v-if="row.link==2">出差加班</span>
                <span v-if="row.link==3">报销</span>
              </template>
        </el-table-column>
        

          <el-table-column align="center"  label="汇报时间" prop="created_at" />
          <el-table-column align="center"  label="原因" prop="mark" />

          <el-table-column align="center"  label="状态" prop="status" >
            <!--1待审核2审核通过3审核拒绝-->
              <template slot-scope="{row}">
                <span v-if="row.status==1">待审核</span>
                <span v-if="row.status==2">审核通过</span>
                <span v-if="row.status==3">审核拒绝</span>
              </template>
          </el-table-column>
          <el-table-column align="center"  label="操作" >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="detailInfo(scope.row.id)"
              >详情</el-button>

            </template>


           </el-table-column> 

         
      </el-table>
      <div style="text-align:center;padding:20px">
        <!--分页-->
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageProps.total"
          :page-size="pageProps.pageSize"
          :page-sizes="[5, 10, 50, 100]"
          :current-page="pageProps.pageNum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      
    </div>

    <detail-dialog ref="detailDialog" @refresh="fetchData"></detail-dialog>

  </div>
    
</template>

<script>
import { jobList,projectList,leaveList,leaveInfo,clockDayList} from "@/api/application/index";

import detailDialog from "@/views/application/approval/detail-dialog.vue";



  export default {
    name: "director-workmaster-clock-month",
    components: { detailDialog},

    data() {
      return {
       
        searchLoading: false,
        pageProps: {
          records:[],
          total: 0,
          pageSize: 10,
          pageNum: 1
        },

        searchFormData:{
          project_id:"",
          link:"1",
          page:0,
          limit:0
        },
        projectDataList:[]

      }
    },

    mounted() {
      this.initProjectList();
    
 
    },


    methods: {

        //项目列表
      initProjectList(){
        var that = this;
        projectList({}).then((response) => {
          console.log('projectList',response);
          that.projectDataList = response.data;
          that.searchFormData.project_id= that.projectDataList[0].project_id
          that.fetchData();
        });
        
      },


      search(){
        this.fetchData();
      },

      fetchData(){
        var that = this;
        that.searchFormData.page=that.pageProps.pageNum;
        that.searchFormData.limit=that.pageProps.pageSize;

        leaveList(that.searchFormData).then((response) => {
          console.log("approval_list",response);
          if(response.code==200){
            that.pageProps.records = response.data.data;
            that.pageProps.total = response.data.total;
          }else{
            that.$message.error(response.msg?response.msg:"请求错误");
          }
         
        });
      },

       // 当前页变更
      handleCurrentChange(num) {
        this.pageProps.pageNum = num
        this.fetchData()
      },


      // 每页显示页面数变更
      handleSizeChange(size) {
        this.pageProps.pageSize = size
        this.fetchData()
      },

    detailInfo(leave_id){
      this.$refs.detailDialog.init(leave_id);
    }
      
    }
  }
</script>


<style lang="scss" scoped>

.report_nav {
  // height: 72px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 19px;
  ul {
    display: flex;

    li {
      position: relative;
      a {
        padding: 0 24px;
        display: block;
        font-size: 16px;
        &.router-link-active {
          //background-color: #4c71db;
          border-bottom: 1px solid #4c71db;
          color:#4c71db ;
          font-weight: bold;
          //color: #fff;
        }
      }
      aside {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        background-color: #ff4e4e;
        border-radius: 10px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
      }
    }

   
  }
}
.report_main {
  border-radius: 6px;
  
}
</style>